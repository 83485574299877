.brochureaboutsection {
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.brochureaboutdiv {
    display: flex;
    width: 85%;
}

.brochureaboutimgdiv,
.brochureaboutdescdiv {
    width: 50%;
}

.brochureaboutimgdiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.brochureaboutimgdiv img {
    width: 90%;
    height: 70vh;
}

.brochureaboutdescdiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.brochureheading {
    background-color: var(--primary);
    padding: 10px;
    font-weight: 700;
    color: white;
    width: 40%;
    text-transform: uppercase;
    clip-path: polygon(84% 0, 100% 47%, 82% 100%, 0 100%, 0 0);
}

.brochureaboutdescdiv p {
    margin: 0;
    text-align: justify;
}

.brochureaboutdescdiv p span {
    font-weight: 600;
    color: var(--primary);
    font-size: 17px;
}

@media screen and (max-width:768px) {
    .brochureaboutdiv {
        width: 95%;
    }
    .brochureheading {
        width: 70%;
    }
    
}

@media screen and (max-width:600px) {
    .brochureaboutsection {
        padding: 50px 0;
    }

    .brochureaboutdiv {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .brochureaboutimgdiv,
    .brochureaboutdescdiv {
        width: 100%;
    }

    .brochureaboutdescdiv {
        gap: 5px;
    }

    .brochureheading {
        width: 50%;
    }

    .brochureaboutimgdiv img {
        width: 100%
    }


}