.maindivsticky1 {
    border-radius: 50%;
    width: fit-content;
    padding: 0.8rem;
    background-color: var(--primary);
    cursor: pointer;
  
}

@keyframes motion {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

.maindivstickytop {
    position: fixed;
    right: 2rem;
    bottom: 3rem;
    z-index: 20;
    width: fit-content;
    animation: motion 1.5s linear infinite;
}


.maindivstickytop:hover {
    animation-play-state: paused;
}

@media screen and (max-width:768px) {
    .maindivstickytop {
        bottom: 1rem;
        right: 1rem;
    }
}


@media screen and (max-width:600px) {
    .maindivstickytop {
        bottom: 5rem;
        /* right: 0.5rem; */
    }
}