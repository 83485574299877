.brochurefootersection {
    padding-top: 50px;
    margin-bottom: 50px;
}

.herosectioninnerdiv3 {
    padding: 0 130px;
    display: flex;
    justify-content: space-between;
}


.brochurefooterdiv{
    background-image: url(../../Assets/brocherfooterbtm.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    padding: 50px 0;
}

.brochurefooterdiv::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}


.brochurefooterbottom {
    background-image: url(../../Assets/brocherfooterbtm.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20vh;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    z-index: 1;
    padding: 50px 0;
}

.brochurefooterbottom::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}

.brochurefooterbtmdiv {
    display: flex;
    width: 80%;
}

.brochureaddressdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
}

.brochureproductimg1 {
    border: 2px solid black;
    height: 350px;
    width: 100%;
}

@media screen and (max-width:768px) {
    .herosectioninnerdiv3 {
        padding: 0 20px;
        align-items: center;
    }

    .brochureproductimg1 {
        height: 250px;
    }

    .brochurefooterbtmdiv {
        width: 95%;
    }

    .brochureaddressdiv h4 {
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width:600px) {

    .brochurefooterbottom {
        padding: 30px 0;
    }

    .herosectioninnerdiv3 {
        padding: 0 20px;
    }

    .brochurefooterbtmdiv {
        width: 95%;
        flex-direction: column;
    }

    .brochureaddressdiv {
        width: 100%;
        align-items: flex-start;
    }

    .brochureaddressdiv h4 {
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }

    .brochureproductimg1 {
        height: 150px;
    }


}