:root {
    --primary: #5b8c51;
    --secondary: #b0362f;
    --light: #F7F8FC;
    --dark: #111111;
}

body{
    background-color: #f8f7f0 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.bg-icon {
    background: url(./Assets/bg-icon.png) center center repeat;
    background-size: contain;
}

a {
    text-decoration: none !important;
}

.section-header {
    position: relative;
    padding-top: 25px;
}

.section-header::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 2px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary);
}

.section-header::after {
    position: absolute;
    content: "";
    width: 90px;
    height: 2px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary);
}

.section-header.text-start::before,
.section-header.text-start::after {
    left: 0;
    transform: translateX(0);
}

.textprimary {
    color: var(--primary);
}

.btn {
    background-color: var(--primary);
}

.leaflet-container {
    height: 600px;
    width: 100%;
}


.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 999;
}


.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

}

.model.open::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    /* height: 135vh; */
    bottom: 0;
    background-color: black;
    opacity: 0.6;
    z-index: -1;
}

.modelcloseButton {
    position: fixed;
    top: 105px;
    right: 10px;
    padding: 5px;
    font-size: 35px;
    background: transparent;
    color: white;
    cursor: pointer;
    z-index: 999;
    border: none;
}

@media screen and (max-width:768px) {
    .modelcloseButton {
        top: 75px;
    } 
}
@media screen and (max-width:600px) {
    .modelcloseButton {
        position: absolute;
        right: 10px;
        top: 75px;
        color: white;
    }
/* 
    .model.open {
        height: 100vh;
    } */
}