@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  /* .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  } */
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 23s slide infinite linear;
  }
  
  .logos-slide img {
    height: 60px;
    margin: 0 40px;
  }

  .logos-slide div {
    display: inline-flex;
    align-items: center;
  }

  .logos-slide div svg,h2{
    font-family: Signika, sans-serif;
    font-size: 100px;
    line-height: 120px !important;
    position: relative !important;
    overflow: visible !important;
    font-weight: 800 !important;
    overflow: visible !important;
    text-shadow: 1px 1px 0 rgba(64, 74, 61, .5), -1px -1px 0 rgba(64, 74, 61, .5), 1px -1px 0 rgba(64, 74, 61, .5), -1px 1px 0 rgba(64, 74, 61, .5), 1px 1px 0 rgba(64, 74, 61, .5);
    -webkit-text-fill-color: #f8f7f0;
    -webkit-text-stroke-width: 0;
    color: transparent;
    letter-spacing: 0;
    margin: 0 20px;
    transition: transform 1s cubic-bezier(.22,.61,.36,1);
  }

  .logos-slide div svg{
    color: var(--primary);
  }

  .logos-slide div h2::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    content: attr(data-text);
    transition: max-width .8s cubic-bezier(.22,.61,.36,1);
    -webkit-text-fill-color:#404a3d;;
    -webkit-text-stroke: transparent;
     max-width: 0;
  }

  .logos-slide div h2:hover::before{
     max-width: 100%;
  }

  @media screen and (max-width:768px) {
    .logos:before {
      left: 0;
      background: transparent;
    }
    
    .logos:after {
      right: 0;
      background: transparent;
    }
  }

  @media screen and (max-width:600px) {
    .logos {
      padding:0;
    }

    .logos-slide div svg,h2{
      font-size: 50px;
    }
  }