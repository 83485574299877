.brochureheadersection {
    padding-top: 130px;
    position: relative;
}

.herosectiondiv {
    background-image: url(../../Assets/brochureheader.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    padding: 50px 0;
}

.herosectiondiv::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.3;
    z-index: -1;
}

.herosectioninnerdiv {
    padding: 0 200px 0 0;
    display: flex;
    justify-content: space-between;
}

.herosectioninnerdiv1 {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
}

.brochurelogodiv {
    width: 45%;
    height: 150px;
    padding: 10px 50px;
    clip-path: polygon(84% 0, 100% 47%, 82% 100%, 0 100%, 0 0);
}

.brochurelogo {
    width: 75%;
    height: 95%;
}

.simpleImage {
    height: 150px;
    width: 150px;
}

.qualityImage{
    height: 200px;
    width: 200px;
}

.herobottomimgdiv {
    display: flex;
    gap: 20px;
}

.herobottomheadingdiv {
    display: flex;
    align-items: center;
}

.herosectionheading {
    font-weight: 700;
    font-size: 3.5rem;
    text-transform: uppercase;
}

.carouselimg {
    height: 95vh;
}

.buttonclass button {
    background: #feb900;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

@media screen and (max-width:1024px) {
    .herosectioninnerdiv {
        padding: 60px 0 0 60px;
    }

}

@media screen and (max-width:768px) {
    .brochureheadersection {
        padding-top: 80px;
    }

    .herosectiondiv {
        height: 65vh;
    }

    .carouselimg {
        height: 65vh;
    }

    .herosectioninnerdiv {
        padding: 0 20px 0 20px;
        align-items: center;
    }

    .brochurelogodiv {
        height: 110px;
        width: 40%;
    }

    .herosectioninnerdiv1 {
        padding: 0 20px;
        gap: 30px;
    }

    .simpleImage {
        height: 120px;
        width: 120px;
    }

    .herosectionheading {
        font-size: 2.5rem;
        text-align: center;
        margin: 0;
    }
}

@media (max-width: 600px) {
    .herosectiondiv {
        height: 60vh;
        padding: 30px 0;
    }

    .carouselimg {
        height: 85vh;
    }

    .brochurelogodiv {
        height: 80px;
        padding: 10px;
    }

    .simpleImage {
        height: 80px;
        width: 80px;
    }

    .herosectioninnerdiv1 {
        flex-direction: column;
    }

    .herosectionheading {
        font-size: 2rem;
    }

    .qualityImage{
        height: 150px;
        width: 150px;
    }
    

}