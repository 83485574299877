/* .about-img img {
    position: relative;
    z-index: 2;
}

.about-img::before {
    position: absolute;
    content: "";
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background-image: -webkit-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -moz-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -ms-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -o-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-size: 20px 20px;
    transform: skew(20deg);
    z-index: 1;
} */
 
.aboutusrow{
    background-color: white;
    border-radius: 30px;
    padding: 30px 0;
    margin: 10px 0;
    height: 90vh;
}

.aboutuscolumn1{
    height: 100%;
}

.aboutuscolumn1 img{
    height: 90%;
    border-radius: 30px;
}

.aboutuscolumn2{
    overflow-y: scroll;
    margin-top: 0;
    height: 100%;
}

.aboutuscolumn2::-webkit-scrollbar {
    width: 5px;
}


.aboutusbtn{
    background-color: var(--primary);
    color: white;
}

.aboutusbtn:hover{
    border: 2px solid var(--primary);
    color: black;
}

.whoarewediv{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: white;
    border-radius: 10px;
    gap: 5px;
    width: 25%;
}

.whoarewediv img{
    height: 20px;
    width: 20px;
}

.whoarewediv p{
    margin: 0;
    font-weight: 600;
}

.aboutcontentdiv{
    padding-left: 2rem;
}

.aboutcontentdiv p{
    text-align: justify;
    display: flex;
}

.aboutcontentdiv p i{
    margin-top: 5px;
}


.aboutcontentdiv h4{
    font-family: Signika, sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0;
    color: #404a3d;
    text-transform: none;
    font-style: normal;
    margin-bottom: 15px;

}

.aboutoptionsdiv{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.aboutoptiondiv1{
    /* background-color: white; */
    background-color: rgb(237, 221, 94);
    border-radius: 50%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
}

.aboutoptiondiv1:hover{
    background-color: var(--primary);
    color: white;
}

.aboutoptiondiv1 svg{
    font-size: 2.5rem;
}

.aboutoptiondiv2{
    width: 85%;
}

.aboutcontentdiv img{
   border-radius: 20px;
    margin-bottom: 20px;
}

.aboutuscontentuldiv{
    display: flex;
}

.aboutuscontentuldiv ul{
    list-style: none;
    padding-left: 0;
}

.aboutuscontentuldiv ul:nth-child(2){
    padding-left: 2rem;
}

@media screen and (max-width:768px) {
    .aboutusrow{
        height: 100%;
        gap: 30px;
        padding: 0;
    }

    .aboutuscolumn2{
        height: 55vh;
    }
}

@media screen and (max-width:600px) {
    .aboutuscolumn2{
        height: 50vh;
        padding: 15px;
    }

    .whoarewediv{
        width: 40%;
    }


    .aboutcontentdiv{
        padding-left: 0;
    }

    .aboutoptiondiv1{
       height: 100px;
       width: 120px;
    }

    .aboutcontentdiv h4{
        font-size: 20px;
    }

    .aboutcontentdiv p{
        text-align: start;
        font-size: 0.8rem;
    }

    .aboutuscontentuldiv ul:nth-child(2){
        padding-left: 0.5rem;
    }
}