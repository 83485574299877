.alt-services {
  padding: 80px 0;
  background-color: #f8f7f0;
}

.alternativeservicesrow{
  height: 90vh;
}

.alternativeservicescol1{
  height: 100%;
  overflow-y: scroll;
}

.alternativeservicescol1::-webkit-scrollbar {
  width: 5px;
}

.alt-services .img-bg {
  background-image: url(../../Assets/dealimage.jpg);
  background-size: cover;
  background-position: center center;
  height: 90%;
}

.dealImage{
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.alt-services h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.alt-services h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.alt-services .icon-box {
  margin-top: 50px;
}

.alt-services .icon-box i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  margin-right: 25px;
  font-size: 28px;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  line-height: 0;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  transition: 0.3s;
}

.alt-services .icon-box:hover i {
  background-color: var(--primary);
  color: white;
}

.alt-services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.alt-services .icon-box h4 a {
  color: #000;
  transition: 0.3s;
}

.alt-services .icon-box h4 a:hover {
  color: var(--color-primary);
}

.alt-services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}


@media screen and (max-width:768px) {
  .alt-services .img-bg {
    min-height: 280px;
  }

  .alternativeservicesrow{
    height: 100%;
  }
  
  .alternativeservicescol1{
    height: 85vh;
  }

}