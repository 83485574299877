.carousel-header {
    margin: 0 15px;
    padding-top: 125px;
    height: 95vh;
}

.carousel-header .carousel-inner .carousel-item {
    /* position: relative; */
    min-height: 80vh;
}

.perfecttext {
    width: 30%;
    padding: 5px;
    letter-spacing: 3px;
    border: 2px solid white;
    border-Radius: 50px
}

.carousel-header .carousel-inner .carousel-item img,
video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
}

.carousel-header .carousel-inner .carousel-item .carousel-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4));
    background-size: cover;
    border-radius: 30px;
}

.carouselheading {
    font-size: 5vw;
    color: #fff;
    line-height: 1.3;
    font-weight: 400;
    font-family: "Amatic SC", cursive
}

.herosectionbtn {
    background-color: var(--primary);
}

.herosectionbtn:hover {
    border: 2px solid var(--primary);
}

.herosectionbottom {
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    /* background-color: beige; */
    background-color: rgb(237, 221, 94);
    padding: 20px 0;
    border-radius: 0 0 30px 30px;
}

.reachoutdiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.scrolldownimg {
    animation: rotate 10s linear infinite;
}

.reachoutdiv h4 {
    font-weight: 600;
}

.reactoutoptiondiv1 {
    border-radius: 50%;
    background-color: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
}

.reactoutoptiondiv1:hover {
    background-color: var(--primary);
    color: white;
}

.reactoutoptiondiv1 svg {
    font-size: 3rem;
}

.videoimagediv{
    position: relative;
}

.videoimagediv img{
    border-radius: 20px;
    cursor: pointer;
}

.videoicon{
    color: white;
    font-size: 3rem;
    position: absolute;
    top: 25%;
    right: 38%;
    cursor: pointer;
}

.youtubeVideo{
    width: 650px;
    height: 400px;
}

@media (max-width: 768px) {
    .carousel-header {
        height: 92vh;
        padding-top: 90px;
    }

    .carouselheading {
        font-size: 9vw;
    }

    
    .perfecttext {
        width: 35%;
    }


    .carousel-header .carousel-control-prev .carousel-control-prev-icon,
    .carousel-header .carousel-control-next .carousel-control-next-icon {
        opacity: 0;
    }

    .search-bar {
        margin-top: -100px;
        transition: 0.5s;
    }

    .herosectionbottom {
      gap: 10px;
      justify-content: space-evenly;
    }

    .reachoutdiv {
        width: 100%;
        gap: 10px;
    }

    .reachoutdiv h5 {
        font-size: 1rem;
    }

    .scrolldownimg {
        height: 120px;
        width: 120px;
    }

    .reactoutoptiondiv1 {
        width: 80px;
        height: 80px;
    }


    .reactoutoptiondiv1 svg {
        font-size: 2rem;
    }

}

@media screen and (max-width:600px) {
    .carousel-header {
        margin: 0 10px;
        padding-top: 85px;
        height: 70vh;
    }

    .carousel-header .carousel-inner .carousel-item {
        min-height: 60vh !important;
    }

    .carousel-header .carousel-inner .carousel-item .carousel-caption {
        padding-top: 40px;
    }

    .herosectionbottom{
        margin: 0 10px;
        flex-wrap: wrap;
    }

    .perfecttext {
        width: 70%;
    }

    .carouselheading {
        font-size: 2.5rem;
    }

    /* .scrolldownimg{
        display: none;
    } */

    .reactoutdiv2{
        flex-direction: row-reverse;
    }

    
    .reachoutdiv h5 {
        font-size: 1.1rem;
    }

    .youtubeVideo{
        width: 400px;
        height: 300px;
    }
}