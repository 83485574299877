
.product-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.product-item img {
    transition: .5s;
    height: 300px;
}

.product-item:hover img {
    transform: scale(1.1);
}

.product-item small button {
    border: none;
    background: transparent;
}

.product-item small a:hover {
    color: var(--primary) !important;
}

.productnamediv{
    height: 110px;
}

.productname{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }

  .product-item2{
    background-color: white;
  }
  

@media screen and (max-width:600px) {

    .productcard{
        padding: 0 5px;
    }
    .product-item{
        display: flex;
    }

    .product-item img {
        height: 155px;
    }

    .product-item1{
        width: 40%;
    }

    .product-item2{
        width: 60%;
    }
}
