.contact-section .contact-info p a {
  color: #1a1a1a;
}

.contact-section .info {
  width: 100%;
}

.contact-section .contact-form {
  width: 100%;
}

.block-9 .form-control {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 15px;
}

.contactspan {
  color: red !important;
  padding: 0 5px;
}