.brochureproductheadingdiv h1{
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.productimagediv{
    padding: 20px;
}

.brochureproductimg{
    border: 5px solid var(--primary);
    height: 350px;
    width: 100%;
}

@media screen and (max-width:768px) {
    .productimagediv{
        padding: 10px;
    }

    .brochureproductimg{
        height: 250px;
    }
}

@media screen and (max-width:600px) {
    .productimagediv{
        padding: 5px;
    }

    .brochureproductimg{
        height: 180px;
    }
}