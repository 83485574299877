.loading{
    width: 100vw;
    height: 50vh;
    background-color: white;
    display: grid;
    place-items: center;
}

.loading > div{
    width: 10vmax;
    height: 10vmax;
    border-bottom: 5px solid rgba(0, 0, 0,0.719);
    border-radius: 50%;
    animation: loading 800ms linear infinite;
}

@keyframes loading {
    to{
        transform: rotateZ(-360deg);
    }
}
