.stats-item {
    background: #fff;
    box-shadow: 0px 0 30px rgba(82, 86, 94, 0.05);
    padding: 15px;
}

.stats-item i {
    font-size: 42px;
    line-height: 0;
    margin-right: 20px;
    color: #feb900;
}

.stats-item h1{
    text-align: center;
}


.stats-item span {
    font-size: 36px;
    /* display: block; */
    font-weight: 600;
    color: #52565e;
    text-align: end;
    width: 100%;
}

.stats-item p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-align: center;
}

.counterImage{
    height: 70px;
    width: 70px;
}

@media screen and (max-width:600px) {
    .stats-item {
        padding: 10px;
    }

    .stats-item span {
        font-size: 30px;
    }
}