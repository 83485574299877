.infrastructuresection{
    background-color: #0059a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 50px 0;
}

.infrastrustureheading h1{
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    word-spacing: 1.5;
    border-bottom: 2px solid white;
}

.infrstructurecontent{
    width: 60%;
    margin-top: 30px;
}

.infrstructurecontent p{
    text-align: justify;
}

.infrstructurecontent h5{
    text-align: center;
}

.infrstructurecontent ul{
    display: flex;
    justify-content: center;
    gap: 30px;
}

.infrastructureimagesdiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    gap: 20px;
}

.infrastructureimagesdiv img{
    border-radius: 15px;
    height: 100px;
    width: 100px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.infrastructureimagesdiv img:nth-child(3){
    height: 200px;
    width: 200px;
}

.infrastructureimagesdiv img:nth-child(2n){
    height: 160px;
    width: 160px;
}

@media screen and (max-width:768px) {
    .infrstructurecontent{
        width: 90%;
    }

    .infrastructureimagesdiv{
        gap: 15px;
    }

    .infrastructureimagesdiv img{
        height: 80px;
        width: 80px;
    }
    
    .infrastructureimagesdiv img:nth-child(3){
        height: 180px;
        width: 180px;
    }
    
    .infrastructureimagesdiv img:nth-child(2n){
        height: 140px;
        width: 140px;
    }
}

@media screen and (max-width:600px) {
    .infrastrustureheading h1{
        font-size: 2.5rem;
        text-align: center;
    }
}