.service-details {
  padding: 50px 0;
}

.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d5d7da;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #d9e3e8;
  margin: 20px 0;
  color: var(--color-secondary);
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
  height: 60vh;
  width: 100%;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary);
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--color-primary);
}

.buynowbtn{
  padding: 15px;
  font-weight: 600;
  background-color: var(--primary); 
  color: white;
  border: none;
  transition: all 0.2ms;
}

.buynowbtn:hover{
  border: 2px solid var(--primary);
  background-color: white;
  color: black !;
}


@media screen and (max-width:600px) {
  .service-details .services-img {
    height: 40vh;
  }
}