.milestonessection {
    color: white;
    background-color: #1f1b4e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0;
}

.wheatimagediv {
    width: 30%;
    display: flex;
    align-items: flex-end;
}

.wheatimagediv img {
    width: 100%;
    height: 100vh;
}

.milestonediv {
    width: 60%;
}

.milestonediv h1 {
    font-weight: 700;
    font-size: 5rem;
    text-align: start;
    text-transform: uppercase;
    margin: 0;
}

.milestonediv h1:nth-child(2) {
    border-bottom: 4px dashed white;
    width: 55%;
}

.milestonediv p {
    width: 95%;
    text-align: justify;
    margin-top: 30px;
    margin-bottom: 0;
}

@media screen and (max-width:768px) {
    .milestonediv {
        width: 70%;
    }

    .wheatimagediv img {
        width: 100%;
        height: 110vh;
    }

    .milestonediv h1 {
        font-size: 3rem;
    }

    .milestonediv p {
        margin-top: 20px;
    }

}

@media screen and (max-width:600px) {

    .milestonessection {
        padding: 30px 0;
    }
    
    .milestonediv h1 {
        font-size: 2rem;
    }


    .wheatimagediv img {
        height: 60vh;
    }

    .milestonediv p {
        margin-top: 10px;
        font-size: 0.5rem;
    }

}