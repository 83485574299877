.presencesection {
    background-color: #a6ce39;
    /* height: 100vh; */
    display: flex;
    padding: 50px 0;
    color: white;
}

.presencecontent {
    width: 60%;
    padding: 0 50px;
}

.presenceimage {
    width: 40%;
}

.presenceimage img {
    width: 100%;
    height: 100%;
}

.presencecontent h1 {
    font-weight: 700;
    font-size: 4rem;
    text-align: start;
    text-transform: uppercase;
    margin: 0;
    border-bottom: 4px solid white;
    width: 60%;
}

.presencecontent p {
    text-align: justify;
    margin: 0;
    margin-top: 30px;
}

.presencemapdiv {
    background-color: white;
    color: black;
    width: 30%;
    border-radius: 80px;
    height: 45vh;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.presencemapdiv p {
    text-align: center;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    margin: 0;
}

@media screen and (max-width:768px) {
    .presencemapdiv {
        width: 50%;
        height: 50vh;
    }
}

@media screen and (max-width:600px) {
    .presencecontent {
        padding: 0 20px;
    }

    .presencecontent h1 {
        font-size: 2rem;
    }


    .presencecontent p {
        font-size: 0.7rem;
    }

    .presencemapdiv {
        width: 100%;
        height: 25vh;
        gap: 10px;
        border-radius: 50px;
        padding: 10px;
    }

}