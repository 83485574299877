.fixed-top {
    transition: .5s;
}

.top-bar {
    height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    background-color: white;
}

.navbar{
    background-color: white;
}


.navbarcontainer {
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: white;
}

.logoimg{
    height: 100%;
    width: 80px;
    margin-left: 50px;

}

.menu-icon {
    display: none;
}

.nav-elements {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 50px;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    height: 80px;
    margin-bottom: 0 !important;
    border: none !important;
    padding-left: 0 !important;
}

.nav-elements ul li {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border: none !important;
}


.nav-elements ul li a {
    font-family: 'Roboto', sans-serif;
    position: relative;
    padding: 25px 15px;
    color: #555555;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    display: flex;
    gap: 5px;
    align-items: center;
    transition: 0.5s ease;
    width: 100%;
    text-decoration: none;
}

.nav-elements ul a:hover,
.nav-elements ul a.active {
    color: var(--primary) !important;
}

.nav-elements ul li a.active::after {
    content: '';
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 2px;
    background-color: var(--primary) !important;
  }
  

@media screen and (max-width:1024px) {
    .logo{
        padding-left: 0;
    }
    
}



@media (max-width: 768px) {

    .navbarcontainer {
        height: 75px;
        width: 100%;
    }

    .logoimg{
        height: 60px;
        width: 100px;
        margin-left: 10px;
    }

    .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        border-radius: 10px;
        transition: .5s;
        opacity: 0;
        position: absolute;
        left: 80px;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        background: var(--bs-light);
        transition: .5s;
        opacity: 1;
    }

    .logo{
        padding-left: 0;
    }
    
    .menu-icon {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        color: black;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 75px;
        background: white;
        width: 0px;
        height: 35vh;
        transition: all 0.3s ease-in;
        overflow: hidden;
        align-items: stretch;
        z-index: 999;
        justify-content: center;
    }

    .nav-elements.active {
        width: 30%;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .nav-elements ul li {
        margin-top: 15px;
    }

    .nav-elements ul li a {
        padding: 10px;
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        justify-content: space-evenly;
        text-align: center;
        color: black !important;
    }

    .nav-elements ul li a.active::after {
        bottom: 6px;
      }

    .fixed-top{
        top: 0 !important;
    }
    .loginlink{
        display: none !important;
    }
}

@media (max-width: 600px) {
    .nav-elements {
        height: 35vh;
    }
    .nav-elements.active {
        width: 50%;
    }
   
}
