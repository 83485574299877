.footer {
    color: #999999;
}

.logodiv{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.logodiv a{
    width: 100%;
}

.footerlogo{
    width: 40%;
    height: 60px;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #999999;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #999999;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}

.downloadbrochurediv{
    border-radius: 30px;
    padding: 10px;
    border: none;
    width: 100%;
    color: white; 
    background-color: var(--primary); 
    display: flex;  
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.5s;
}

.downloadbrochurediv:hover{
    color: var(--primary); 
    background-color: white; 
}
.svgdiv{
    font-size: 1.2rem;
}

.downloaddiv{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color:var(--primary);
}

.downloadbrochurediv:hover .downloaddiv{
    background-color: var(--primary);
    color: white;
}

@media screen and (max-width:600px) {
    .footerlogo{
        height: 70px;
    }
}
