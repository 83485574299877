.aboutuspagerow {
    /* background-color: rgb(237, 221, 94); */
    /* --pbmit-agrimo-global-color-rgb: 237, 221, 94 */
    width: 90%;
    padding-bottom: 30px;
    margin-top: 10px;
    border-radius: 30px;
}

.aboutuspagecolumn1 {
    position: relative;
}

.aboutuspageimage {
    height: 80vh;
}

.slogandiv {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #eddd5e;
    height: 240px;
    width: 265px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.slogandiv h1 {
    font-weight: 700;
    text-align: center;
}



.aboutuspageimage {
    clip-path: polygon(0 0, 100% 0, 100% 60%, 60% 60%, 60% 100%, 0 100%);
    border-radius: 20px;
}

.aboutpagecontentdiv {
    display: flex;
    gap: 30px;
}

.aboutpageoptionsdiv {
    width: 50%;
}


.aboutpageoptiondiv1 {
    margin-bottom: 20px;
}

.aboutpageoptiondiv1 svg {
    font-size: 4rem;
}


@media screen and (max-width:768px) {
    .aboutuspagerow {
        padding: 0 20px;
    }


    .slogandiv {
        height: 175px;
        width: 255px;
    }

    .aboutuspageimage{
        height: 60vh;
    }
}

@media screen and (max-width:600px) {
    .aboutuspagerow {
        width: 95%;
    }

    .aboutuspagecolumn1,
    .aboutpagecolumn2 {
        padding: 0;
    }

    .slogandiv {
        height: 37%;
        width: 37%;
    }

    .aboutuspageimage {
        height: 40vh;
        width: 100%;
    }

    .aboutpageoptiondiv2 h5 {
        font-size: 1rem;
    }

    .aboutpageoptiondiv2 p {
        font-size: 0.9rem;
    }

    .aboutpagecolumn2 h3 {
        font-size: 1.2rem !important;
    }

    .slogandiv h1 {
        font-size: 1.5rem;
    }

}