.testimonial-item {
    background: var(--primary) !important;
    margin: 0 20px;

}

.testimonial-item * {
    color: #FFFFFF;
}

.testimonialImage{
    height: 80px;
    width: 80px;
}